@use '../config/' as *;
@forward '../main-scss/';
@forward './commons';
@forward './stats-nav';
@forward './table';
.waf-statslisting {
    --card-height: 22rem;
    padding: 0 var(--space-1);
    // @extend %pt-5;
    @extend %mb-3;
    .waf-head {
        padding-right: 12rem;
        @extend %flex-sb-n;
        .title {
            @extend %mb-2;
        }
    }
    .waf-body {
        padding-top: 3rem;
        @extend %relative;
        @extend %mx-3;
    }
    .player-card ~ .stats-table {
        .table-head {
            margin-bottom: var(--card-height);
        }
    }
    .table {
        @extend %mt-6;
    }
    .stats-table {
        .player-name:hover {
            text-decoration: underline;
        }
    }
    .player-card {
        position: absolute;
        left: 0;
        height: var(--card-height);
        top: 11rem;
        background: linear-gradient(356.76deg, var(--club-primary) -80.63%, var(--club-secondary) 48.47%);
        @extend %w-100;
        @extend %flex-sb-c;
        @extend %relative;
        @extend %white;
        a {
            &:hover {
                text-decoration: underline;
            }
        }
        .position {
            position: absolute;
            top: 1rem;
            left: 2rem;
            .text {
                -webkit-text-stroke: .1rem hsl(var(--hsl-white)/.5);
                color: transparent;
                @extend %font-20-pb;
            }
        }
        .club-name {
            @extend %white;
            .full-name {
                text-transform: none;
            }
        }
        .player,
        .player-name {
            line-height: 1.2;
            @extend %white;
            @extend %d-block;
            @extend %mb-2;
            .name {
                @extend %d-block;
                @extend %font-16-pr;
                @extend %uppercase;
                @extend %white;
                &:last-child {
                    font-weight: 700;
                }
            }
            &-role {
                @extend %pb-2;
                @extend %mb-2;
                @include border(1, white, 3, bottom);
                .role {
                    width: max-content;
                    line-height: 1.8;
                    @extend %uppercase;
                    @extend %px-1;
                    @extend %font-8-pr;
                    @extend %white-bg-1;
                }
            }
            &-image {
                width: 45%;
                @extend %flex-c-fe;
                @extend %h-100;
                .image {
                    @extend %w-100;
                    @extend %h-100;
                }
            }
            &-info {
                flex: 1;
                width: 55%;
                @extend %px-1;
            }
            &-meta {
                @extend %pr-4;
                @extend %mt-2;
                @include grid(2)
            }
            &-data {
                @extend %white-bg-1;
                @extend %p-1;
                @extend %text-center;
                .text {
                    height: 2.6rem;
                    @extend %font-8-pr;
                    @extend %white-7;
                    @extend %flex-c-c;
                }
                .value {
                    line-height: 1;
                    @extend %mt-1;
                    @extend %font-22-pb;
                    @extend %white;
                }
            }
        }
    }
    .club {
        &-img {
            width: 4rem;
            height: 4rem;
            flex-shrink: 0;
            @extend %white-bg;
            @extend %circle-radius;
        }
        &-wrap {
            @extend %flex-n-c;
            @extend %gap-1;
        }
        &-name {
            // @extend %white;
            @extend %font-8-pr;
            &:hover {
                text-decoration: underline;
            }
            .short-name {
                @extend %d-none;
            }
        }
    }
}
@include mq(col-md) {
    .waf-statslisting {
        --card-height: 21rem;
        .waf-body {
            padding-top: 0;
            padding-inline: 0;
            margin-inline: 0;
        }
        .table-head {
            .table-data-player {
                margin-left: 2.5rem;
            }
        }
        .club-name {
            display: block;
        }
        .player-card {
            top: 5rem;
            .position {
                align-self: center;
                position: unset;
                margin-left: 5.5rem;
                .text {
                    font-size: 6rem;
                }
            }
            .player,
            .player-name {
                &-image {
                    width: 20rem;
                }
                &-info {
                    display: grid;
                    grid-template-columns: 62% 1fr;
                    margin-left: 10%;
                }
                .name {
                    display: inline;
                    font-size: 2.6rem;
                }
                &-role {
                    max-width: 30rem;
                    .role {
                        font-size: 1rem;
                        padding-inline: var(--space-2);
                    }
                }
                &-meta {
                    grid-row: 1 / span 3;
                    grid-column-start: 2;
                }
                &-data {
                    background: transparent;
                    .text {
                        display: none;
                        &.value {
                            display: block;
                            font-size: 4.2rem;
                        }
                    }
                }
            }
            .club {
                &-img {
                    width: 6rem;
                    height: 6rem;
                    padding: 0.5rem;
                }
                &-name {
                    display: block;
                    font-size: 1.4rem;
                }
            }
        }
    }
    .stats-navigation {
        top: 25.3rem;
    }
}
@include mq(col-xxl) {
    .waf-statslisting {
        .player-card {
            .player-info {
                margin-left: 13.5%;
                grid-template-columns: 61% 1fr;
            }
        }
    }
}